*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #dc2283;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #9a185c;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.table, table.listing {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th, table.listing th,
.table td,
table.listing td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th, table.listing thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody, table.listing tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table, table.listing .table, .table table.listing, table.listing table.listing {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered, table.listing {
  border: 1px solid #dee2e6;
}

.table-bordered th, table.listing th,
.table-bordered td,
table.listing td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th, table.listing thead th,
.table-bordered thead td,
table.listing thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd), table.listing tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f5c1dc;
}

.table-hover .table-primary:hover {
  background-color: #f1abd0;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #f1abd0;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th, table.listing .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th, table.listing .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered, table.table-dark.listing {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd), table.table-dark.listing tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered, .table-responsive-sm > table.listing {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered, .table-responsive-md > table.listing {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered, .table-responsive-lg > table.listing {
    border: 0;
  }
}

@media (max-width: 1099.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered, .table-responsive-xl > table.listing {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered, .table-responsive > table.listing {
  border: 0;
}

/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: local("Titillium Web Italic"), local("TitilliumWeb-Italic"), url(https://fonts.gstatic.com/s/titilliumweb/v6/r9OmwyQxrgzUAhaLET_KOyohs_GVCV93aZwGb7eT-mc.woff2) format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1-1eff, U+20a0-20ab, U+20ad-20cf, U+2c60-2c7f, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: local("Titillium Web Italic"), local("TitilliumWeb-Italic"), url(https://fonts.gstatic.com/s/titilliumweb/v6/r9OmwyQxrgzUAhaLET_KO04Sq3N3sm-tF9FpL8sHob4.woff2) format("woff2");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2212, U+2215;
}

/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"), url(https://fonts.gstatic.com/s/titilliumweb/v6/7XUFZ5tgS-tD6QamInJTcSGR3J8a2Jm30YJvJ3tIMOY.woff2) format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1-1eff, U+20a0-20ab, U+20ad-20cf, U+2c60-2c7f, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"), url(https://fonts.gstatic.com/s/titilliumweb/v6/7XUFZ5tgS-tD6QamInJTceHuglUR2dhBxWD-q_ehMME.woff2) format("woff2");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2212, U+2215;
}

/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: local("Titillium Web Bold"), local("TitilliumWeb-Bold"), url(https://fonts.gstatic.com/s/titilliumweb/v6/anMUvcNT0H1YN4FII8wpr6nZAMwsGZpiGJKtVINElMQ.woff2) format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1-1eff, U+20a0-20ab, U+20ad-20cf, U+2c60-2c7f, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: local("Titillium Web Bold"), local("TitilliumWeb-Bold"), url(https://fonts.gstatic.com/s/titilliumweb/v6/anMUvcNT0H1YN4FII8wpr8hG3LOB74UqS1hPmWaAxzQ.woff2) format("woff2");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2212, U+2215;
}

.plone-modal .plone-modal-content, .top-container, #portal-header, .documentDescription,
.documentByLine,
#content-core,
.wcvideo-before-title,
.wcvideo-title,
.newsitem-title,
#category, .template-newsitem_view .leadImage, .contatti-footer .portletContent, .info-footer .portletContent, .tilesWrapper .actionsTiles, .tile-collection-container, .tile-advanced-static .tile-container, .template-event_schedule .filters-content,
.template-event_schedule #results-items {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .plone-modal .plone-modal-content, .top-container, #portal-header, .documentDescription,
  .documentByLine,
  #content-core,
  .wcvideo-before-title,
  .wcvideo-title,
  .newsitem-title,
  #category, .template-newsitem_view .leadImage, .contatti-footer .portletContent, .info-footer .portletContent, .tilesWrapper .actionsTiles, .tile-collection-container, .tile-advanced-static .tile-container, .template-event_schedule .filters-content,
  .template-event_schedule #results-items {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .plone-modal .plone-modal-content, .top-container, #portal-header, .documentDescription,
  .documentByLine,
  #content-core,
  .wcvideo-before-title,
  .wcvideo-title,
  .newsitem-title,
  #category, .template-newsitem_view .leadImage, .contatti-footer .portletContent, .info-footer .portletContent, .tilesWrapper .actionsTiles, .tile-collection-container, .tile-advanced-static .tile-container, .template-event_schedule .filters-content,
  .template-event_schedule #results-items {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .plone-modal .plone-modal-content, .top-container, #portal-header, .documentDescription,
  .documentByLine,
  #content-core,
  .wcvideo-before-title,
  .wcvideo-title,
  .newsitem-title,
  #category, .template-newsitem_view .leadImage, .contatti-footer .portletContent, .info-footer .portletContent, .tilesWrapper .actionsTiles, .tile-collection-container, .tile-advanced-static .tile-container, .template-event_schedule .filters-content,
  .template-event_schedule #results-items {
    max-width: 960px;
  }
}

@media (min-width: 1100px) {
  .plone-modal .plone-modal-content, .top-container, #portal-header, .documentDescription,
  .documentByLine,
  #content-core,
  .wcvideo-before-title,
  .wcvideo-title,
  .newsitem-title,
  #category, .template-newsitem_view .leadImage, .contatti-footer .portletContent, .info-footer .portletContent, .tilesWrapper .actionsTiles, .tile-collection-container, .tile-advanced-static .tile-container, .template-event_schedule .filters-content,
  .template-event_schedule #results-items {
    max-width: 1060px;
  }
}

.template-event_schedule #filters-form {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.portalMessage a, .contatti-footer .portletContent p > a:not(.button), .info-footer .portletContent a {
  display: inline-block;
  line-height: 1;
  color: white;
  text-decoration: none;
  border-bottom: 0.1em dotted currentColor;
}

.portalMessage a:hover, .contatti-footer .portletContent p > a:hover:not(.button), .info-footer .portletContent a:hover, .portalMessage a:focus, .contatti-footer .portletContent p > a:focus:not(.button), .info-footer .portletContent a:focus {
  border-bottom-style: solid;
}

.hiddenStructure, .top-container a.social span, #submenu-details .closeSubmenu, .wcvideo-container .me-cannotplay a span, .contatti-footer .portletContent li a span, .section-homepage .documentFirstHeading, .banner-solo-foto .tileTitle, .template-search #searchform #searchResultsSort #sorting-options a span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.portalMessage {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.portalMessage a {
  color: inherit;
  font-weight: 700;
}

.portalMessage strong {
  margin-right: 0.5rem;
}

.portalMessage.info {
  color: #0f6674;
  background-color: rgba(23, 162, 184, 0.05);
  border-color: #17a2b8;
}

.portalMessage.error {
  color: #a71d2a;
  background-color: rgba(220, 53, 69, 0.05);
  border-color: #dc3545;
}

.portalMessage.warning {
  color: #6d5200;
  background-color: rgba(255, 193, 7, 0.05);
  border-color: #ffc107;
}

fieldset legend {
  color: #495057;
  border-bottom: 1px solid #ced4da;
}

.field {
  margin-bottom: 0.5rem;
}

.field.error {
  padding: 0.5rem 0.8rem;
  background-color: rgba(220, 53, 69, 0.1);
}

.field.error label {
  color: #bd2130;
}

.field.error input {
  border: 1px solid #dc3545;
}

.fieldRequired {
  font-size: 0.7em;
  color: #495057;
}

.formHelp {
  font-size: 0.9em;
  color: #343a40;
  font-style: italic;
}

.formControls, .plone-modal .plone-modal-dialog .plone-modal-footer {
  border-top: 1px solid #ced4da;
  background: #f8f9fa;
  padding: 0.7rem;
}

input[type='text'],
input[type='password'],
input[type='email'] {
  height: 2.25rem;
  line-height: 2.25rem;
  padding: 0 0.5rem;
  border-radius: 2px;
  border: 1px solid #ced4da;
}

input[type='file'],
input[type='submit'],
input[type='button'] {
  display: inline-block;
  height: 2.25rem;
  line-height: 2.25rem;
  user-select: none;
  border: 1px solid #dee2e6;
  padding: 0 0.8rem;
  border-radius: 3px;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type='file']:hover, input[type='file']:focus,
input[type='submit']:hover,
input[type='submit']:focus,
input[type='button']:hover,
input[type='button']:focus {
  background-color: #e9ecef;
  border-color: #ced4da;
}

input[type='file'].focus, input[type='file']:focus,
input[type='submit'].focus,
input[type='submit']:focus,
input[type='button'].focus,
input[type='button']:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 34, 131, 0.25);
}

input[type='file'].disabled, input[type='file']:disabled,
input[type='submit'].disabled,
input[type='submit']:disabled,
input[type='button'].disabled,
input[type='button']:disabled {
  opacity: 0.65;
}

input[type='file'].context,
input[type='submit'].context,
input[type='button'].context {
  color: white;
  background-color: #17a2b8;
  border-color: #148ea1;
}

input[type='file'].context:hover, input[type='file'].context:focus,
input[type='submit'].context:hover,
input[type='submit'].context:focus,
input[type='button'].context:hover,
input[type='button'].context:focus {
  background-color: #148ea1;
  border-color: #117a8b;
}

input[type='file'].destructive,
input[type='submit'].destructive,
input[type='button'].destructive {
  color: white;
  background-color: #dc3545;
  border-color: #d32535;
}

input[type='file'].destructive:hover, input[type='file'].destructive:focus,
input[type='submit'].destructive:hover,
input[type='submit'].destructive:focus,
input[type='button'].destructive:hover,
input[type='button'].destructive:focus {
  background-color: #d32535;
  border-color: #bd2130;
}

body.mce-content-body {
  padding: 0.3rem;
}

.tinyAlignCenter {
  text-align: center;
}

.tinyAlignLeft {
  text-align: left;
}

.tinyAlignRight {
  text-align: right;
}

.pat-dropzone .dz-notice {
  margin-bottom: 0.4rem;
}

.pat-dropzone .dz-default {
  border-radius: 5px;
  background-color: #f2f1f1;
  border: 3px dotted #ccc;
  margin-bottom: 20px;
  padding: 0.75rem;
  color: #696969;
}

.plone-modal {
  display: none;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  z-index: 1040;
  background: rgba(255, 255, 255, 0.5);
}

.plone-modal .plone-modal-dialog {
  width: 100%;
  position: relative;
}

.plone-modal .plone-modal-dialog .plone-modal-header {
  min-height: 1.5rem;
  padding: 0.75rem;
}

.plone-modal .plone-modal-dialog .plone-modal-header h3 {
  margin: 0;
}

.plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close {
  margin-top: -0.75rem;
  float: right;
  font-size: 2em;
  font-weight: 100;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}

.plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close:hover, .plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close:focus {
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}

.plone-modal .plone-modal-dialog .plone-modal-body {
  padding: 0.75rem;
}

.plone-modal .plone-modal-dialog .plone-modal-body .modal-image {
  text-align: center;
}

.plone-modal .plone-modal-dialog .plone-modal-footer {
  padding: 0.75rem;
  text-align: right;
}

.plone-modal .plone-modal-dialog .plone-modal-footer .pattern-modal-buttons input + input {
  margin-left: 0.75rem;
}

.plone-modal .plone-modal-content {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  border: 0;
  border-radius: 4px;
  outline: none;
  background-clip: padding-box;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
  padding-left: 0;
  padding-right: 0;
}

.plone-modal.fade {
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.plone-modal.fade.in {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.plone-modal.fade .plone-modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.25s ease-out;
  -moz-transition: -moz-transform 0.25s ease-out;
  -o-transition: -o-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}

.plone-modal.fade.in .plone-modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.plone-modal.in {
  display: block !important;
}

.plone-modal-loading {
  float: left;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #fff;
  text-align: center;
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 2.5rem 2.5rem;
  border-radius: 0.5em;
  width: 40em;
  height: 2.5rem;
}

.plone-modal-open {
  padding-right: 0;
}

.pagination {
  display: inline-block;
  padding: 0;
  margin: 1.25rem 0;
}

.pagination ul {
  list-style: none;
  padding: 0;
}

.pagination li {
  display: block;
  float: left;
  position: relative;
  padding: 0;
  font-weight: 400;
  /* wanna hide the label "next ... items"? */
  /* .label {
        display: none;
      } */
}

.pagination li > a,
.pagination li > span {
  padding: 0.375rem 0.75rem;
  line-height: 1.25;
  text-decoration: none;
  color: #dc2283;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
}

.pagination li.previous > a {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination li.previous .arrow:before {
  content: '\3c';
}

.pagination li.next > a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination li.next .arrow:after {
  content: '\3e';
}

.pagination li > a:hover, .pagination li > a:focus {
  color: #9a185c;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  box-shadow: 0 0 0 0.2rem rgba(220, 34, 131, 0.25);
}

.pagination li.active > span {
  z-index: 2;
  font-weight: 400;
  color: #fff;
  background-color: #dc2283;
  border: 1px solid #dc2283;
  cursor: default;
}

.pagination li.disabled > span {
  color: #6c757d;
  background-color: #fff;
  border: 1px solid #dee2e6;
  cursor: default;
}

@media (max-width: 575.98px) {
  .pagination li {
    display: none;
  }
  .pagination li.previous,
  .pagination li.next {
    display: inline;
  }
}

.pat-autotoc:not(.autotabs) .autotoc-nav {
  float: right;
  min-width: 150px;
  background-color: #f2f1f1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.17);
  padding: 0.4rem 0.75rem;
  margin: 0 0 0.75rem 0.75rem;
}

.pat-autotoc:not(.autotabs) .autotoc-nav a {
  display: block;
}

.pat-autotoc:not(.autotabs) .autotoc-nav .active,
.pat-autotoc:not(.autotabs) .autotoc-nav .active:hover {
  color: #696969;
  cursor: default;
  text-decoration: none;
}

.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-1 {
  margin-left: 0;
  font-size: 1.44rem;
}

.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-2 {
  margin-left: 0.75rem;
  font-size: 1.2rem;
}

.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-3 {
  margin-left: 1.5rem;
  font-size: 0.96rem;
}

@media (max-width: 767.98px) {
  .pat-autotoc .autotoc-nav {
    float: none;
    margin: 0 0 20px 0;
  }
}

.top-bar {
  background-color: #dc2283;
}

@media (min-width: 768px) {
  .top-bar {
    height: 3.2rem;
  }
}

.top-container {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.top-container a.social {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: white;
  color: #343a40;
  border-radius: 50%;
  text-align: center;
  line-height: 2rem;
  margin-right: 1.2rem;
}

.top-container a.social:hover, .top-container a.social:focus {
  color: #dc2283;
}

@media (max-width: 767.98px) {
  .top-container {
    text-align: center;
  }
  .top-container a.social:last-child {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.info-links a {
  color: white;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-left: 1.5rem;
  text-decoration: none;
}

.info-links a:hover, .info-links a:focus {
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .info-links {
    padding-top: 0.2rem;
    margin-top: 0.6rem;
    border-top: 1px solid white;
  }
  .info-links a:first-child {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  body#visual-portal-wrapper {
    padding-top: 7.88rem;
  }
  #portal-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.117647);
    z-index: 1000;
  }
}

@media screen and (min-width: 770px) {
  .plone-toolbar-left-default #portal-top {
    left: 60px;
  }
  .plone-toolbar-left-expanded #portal-top {
    left: 120px;
  }
}

#portal-header {
  padding-top: 1px;
  padding-bottom: 1px;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) {
  #portal-header {
    height: 4.68rem;
  }
}

#portal-logo {
  margin: 0.4rem 0;
}

#portal-logo img {
  height: 60px;
  width: auto;
}

#portal-globalnav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 1.125rem;
}

#portal-globalnav > li {
  position: relative;
}

#portal-globalnav > li > a {
  text-transform: uppercase;
  color: #212529;
  transition-property: color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  text-decoration: none;
  display: inline-block;
}

#portal-globalnav > li > a:hover, #portal-globalnav > li > a:focus {
  color: #dc2283;
}

#submenu-details .submenuDetailsContent {
  background-color: #f2efef;
  min-width: 180px;
}

#submenu-details .submenuDetailsContent ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

#submenu-details .submenuDetailsContent ul > li > a {
  padding: 0.5rem 1.25rem;
  display: block;
  color: #212529;
  text-decoration: none;
  transition: 0.2s background-color ease;
}

#submenu-details .submenuDetailsContent ul > li > a:hover, #submenu-details .submenuDetailsContent ul > li > a:focus {
  background-color: #f8f8f8;
}

#submenu-details .submenuDetailsContent ul > li + li > a {
  border-top: 1px solid #dcdadb;
}

.mobile-button {
  align-self: center;
  font-size: 1.5em;
  color: #dc2283;
}

@media (min-width: 768px) {
  .mobile-button {
    display: none;
  }
  .globalnavWrapper {
    display: block !important;
  }
  #portal-globalnav {
    display: flex;
    height: 100%;
  }
  #portal-globalnav > li {
    margin-left: 15px;
  }
  #portal-globalnav > li > a {
    border-top: 3px solid transparent;
    padding-top: 22px;
    height: 100%;
  }
  #portal-globalnav > li > a:hover, #portal-globalnav > li > a:focus {
    border-top-color: #dc2283;
  }
  #submenu-details {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
  }
  #submenu-details .submenuDetailsContent {
    border-top: 3px solid #dc2283;
    box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.06);
  }
}

@media (min-width: 992px) {
  #portal-globalnav > li {
    margin-left: 35px;
  }
}

@media (max-width: 767.98px) {
  #portal-logo {
    margin: 0.8rem 0;
  }
  #portal-header {
    flex-flow: row wrap;
  }
  .globalnavWrapper {
    display: none;
    width: 100%;
  }
  #portal-globalnav > li > a {
    background-color: white;
    border-top: 1px solid #dadada;
    line-height: 2.5em;
    display: block;
  }
  #portal-globalnav > li > a:active {
    background-color: #f9f9f9;
  }
}

a.button {
  display: inline-block;
  background-color: #dc2283;
  color: white;
  text-transform: uppercase;
  font-size: 0.9rem;
  transition: 0.2s all ease;
  padding: 1em 2.1em;
  text-decoration: none;
}

a.button:hover, a.button:focus {
  background-color: #212529;
  color: #dc2283;
}

h1 {
  font-size: 1.8em;
}

h1.documentFirstHeading {
  margin: 0;
  padding: 1.5rem 1rem 2rem;
  text-align: center;
  background-color: #dc2283;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
  h1.documentFirstHeading {
    font-size: 3rem;
    padding: 2.5rem 1rem 4rem;
  }
}

.documentDescription {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#category {
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#category .cat-wrapper {
  padding: 0.3125rem 0;
  border-top: 1px solid #e0dede;
  border-bottom: 1px solid #e0dede;
}

#category ul {
  padding: 0;
  display: inline;
  list-style: none;
}

#category ul > li {
  display: inline;
}

.documentByLine {
  color: #6c757d;
  margin-bottom: 0.5rem;
  font-size: 0.95em;
}

pre {
  padding: 0.3rem 0.6rem;
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  border-radius: 3px;
  font-size: 0.9em;
}

#parent-fieldname-text {
  min-height: 10rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.template-summary_view .tileItem {
  margin-bottom: 1.8rem;
}

.template-summary_view .documentByLine {
  font-size: 0.8em;
}

.template-listing_view .entry {
  margin-bottom: 1.5rem;
}

.template-full_view .description {
  margin-bottom: 0.6rem;
}

.template-full_view .item {
  margin-bottom: 2rem;
}

.wcvideo-container iframe {
  max-width: 100%;
}

@media (max-width: 991.98px) {
  .wcvideo-container iframe {
    max-height: 56vw;
  }
}

.wcvideo-title,
.newsitem-title {
  font-weight: 400;
  margin-top: 1rem;
}

.template-newsitem_view .leadImage img {
  max-width: 100%;
  height: auto;
}

#content::after,
#content-core::after {
  display: block;
  clear: both;
  content: "";
}

.contatti-footer {
  background-color: #4C4C4C;
  padding-top: 1.6rem;
  padding-bottom: 3.2rem;
}

.contatti-footer .portletHeader {
  display: none;
}

.contatti-footer .portletContent {
  text-align: right;
  color: white;
  text-transform: uppercase;
}

.contatti-footer .portletContent h3 {
  float: left;
  font-weight: 400;
  font-size: 1.2rem;
  width: 100%;
}

@media (min-width: 576px) {
  .contatti-footer .portletContent h3 {
    width: 33%;
  }
}

.contatti-footer .portletContent ul {
  list-style: none;
  padding: 0;
}

.contatti-footer .portletContent li {
  display: inline;
}

.contatti-footer .portletContent li a {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  color: #495057;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1.5rem;
  margin-left: 0.3rem;
}

.contatti-footer .portletContent li a:hover, .contatti-footer .portletContent li a:focus {
  color: #dc2283;
}

.info-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #dc2283;
}

.info-footer .portletHeader {
  display: none;
}

.info-footer .portletContent {
  text-align: center;
  color: white;
  font-size: 0.9rem;
}

.info-footer .portletContent p {
  margin: 0;
}

.pat-tiles-management {
  margin: 0;
}

.tile-collapse-button {
  border: 0;
  background: none;
  padding-left: 0;
  position: relative;
  padding-right: 30px;
}

.tile-collapse-button .title-content {
  border-bottom: 1px solid #000;
}

.tile-collapse-button .title-icon svg {
  display: none;
  margin-left: 10px;
}

.tile-collapse-button .title-icon svg.fa-angle-down {
  display: inline-block;
}

.sfondo-scuro .tile-collapse-button {
  color: #fff;
}

.sfondo-scuro .tile-collapse-button .title-content {
  border-bottom-color: #fff;
}

.tile-collapse-button:active, .tile-collapse-button:hover, .tile-collapse-button:focus {
  background: inherit;
  box-shadow: none;
  outline: none;
}

.tilesWrapper {
  margin: 0;
}

.tilesWrapper .tile::after {
  display: block;
  clear: both;
  content: "";
}

.tileWrapper > .collapsible-desktop .tileBody,
.tileWrapper > .collapsible-desktop .tileContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.tileWrapper > .collapsible-desktop .showMore {
  display: none;
}

.tileWrapper > .collapsible-desktop:not(.open) .tileBody,
.tileWrapper > .collapsible-desktop:not(.open) .tileContent {
  padding: 0;
  margin: 0;
}

.tileWrapper > .collapsible-desktop.open .tileBody,
.tileWrapper > .collapsible-desktop.open .tileContent {
  max-height: 10000px;
}

.tileWrapper > .collapsible-desktop.open .tileBody h2,
.tileWrapper > .collapsible-desktop.open .tileContent h2 {
  margin: 5px 0 10px;
}

.tileWrapper > .collapsible-desktop.open .showMore {
  display: block;
}

.tileWrapper > .collapsible-desktop.open .tileTitle {
  margin-bottom: 15px;
}

.tileWrapper > .collapsible-desktop.open .tile-collapse-button {
  cursor: pointer;
}

.tileWrapper > .collapsible-desktop.open .tile-collapse-button .title-icon svg.fa-angle-down {
  display: none;
}

.tileWrapper > .collapsible-desktop.open .tile-collapse-button .title-icon svg.fa-angle-up {
  display: inline-block;
}

@media (max-width: 991.98px) {
  .tileWrapper > .collapsible .tileBody,
  .tileWrapper > .collapsible .tileContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  .tileWrapper > .collapsible .showMore {
    display: none;
  }
  .tileWrapper > .collapsible:not(.open) .tileBody,
  .tileWrapper > .collapsible:not(.open) .tileContent {
    padding: 0;
    margin: 0;
  }
  .tileWrapper > .collapsible.open .tileBody,
  .tileWrapper > .collapsible.open .tileContent {
    max-height: 10000px;
  }
  .tileWrapper > .collapsible.open .tileBody h2,
  .tileWrapper > .collapsible.open .tileContent h2 {
    margin: 5px 0 10px;
  }
  .tileWrapper > .collapsible.open .showMore {
    display: block;
  }
  .tileWrapper > .collapsible.open .tileTitle {
    margin-bottom: 15px;
  }
  .tileWrapper > .collapsible.open .tile-collapse-button {
    cursor: pointer;
  }
  .tileWrapper > .collapsible.open .tile-collapse-button .title-icon svg.fa-angle-down {
    display: none;
  }
  .tileWrapper > .collapsible.open .tile-collapse-button .title-icon svg.fa-angle-up {
    display: inline-block;
  }
}

@media (max-width: 991.98px) {
  .mobile-hidden {
    display: none;
  }
}

.senza-titolo .tileTitle,
.senza-titolo .tile-title {
  display: none;
}

.tile-collection .collectionItemImage img {
  width: 100%;
  height: auto;
}

.tile-collection ul.tileContent {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
  margin-left: -0.6rem;
  margin-right: -0.6rem;
  margin-bottom: 0;
}

.tile-collection li.collectionItem {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  padding: 1.25rem 0.6rem;
}

.tile-collection .showMore {
  position: absolute;
  top: 1.6em;
  right: 2%;
}

.tile-collection .showMore a {
  font-size: 2rem;
  font-weight: bold;
}

.tile-collection .tileTitle {
  font-size: 1.6em;
}

.tile-collection .collectionItemTitle {
  color: #cf207b;
  text-decoration: underline;
  font-size: 1.35em;
  line-height: 1.3;
}

.tile-collection .collectionItemWrapper {
  background-color: #eee;
  height: 100%;
  position: relative;
}

.tile-collection .collectionItemWrapper a {
  color: #212529;
  height: 100%;
  display: block;
}

.tile-collection .collectionItemWrapper a:hover, .tile-collection .collectionItemWrapper a:focus {
  background-color: #f5f5f5;
}

.tile-collection .collectionItemWrapper a:hover .collectionItemImage:after, .tile-collection .collectionItemWrapper a:focus .collectionItemImage:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.tile-collection .collectionItemText {
  padding: 1.25rem;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  justify-content: space-between;
}

.tile-collection .collectionItemDates {
  padding-top: 1.25rem;
  text-align: right;
  color: #555;
}

.tile-collection .collectionItemDates > div {
  display: inline-block;
}

.tile-collection .collectionItemDescription {
  font-size: 1.7rem;
}

@media (min-width: 576px) {
  .tile-collection li.collectionItem {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .tile-collection ul.tileContent {
    font-size: 0.9em;
  }
  .tile-collection li.collectionItem {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.collectionTile.carousel div[class^='carousel-wrapper'] {
  position: relative;
}

.collectionTile.carousel ul.tileContent {
  display: block;
}

.collectionTile.carousel li.collectionItem {
  flex: 1;
}

.collectionTile.carousel .slick-list {
  width: 93%;
  margin: auto;
}

.collectionTile.carousel .slick-track {
  display: flex;
  flex-flow: row nowrap;
}

.collectionTile.carousel .slick-slide {
  height: auto;
}

.collectionTile.carousel ul.slick-dots {
  width: 100%;
  padding: 15px 0 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.collectionTile.carousel ul.slick-dots li {
  padding: 0 5px;
}

.collectionTile.carousel ul.slick-dots li button {
  padding: 0;
  overflow: hidden;
  width: 12px;
  height: 12px;
  text-indent: 1000px;
  border-radius: 4px;
  background: none;
  border: 1px solid #ccc;
}

.collectionTile.carousel ul.slick-dots li.slick-active button {
  background-color: rgba(0, 0, 0, 0.67);
}

.collectionTile.carousel .slick-arrow {
  position: absolute;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  padding: 0 0 0 30px;
  overflow: hidden;
  z-index: 100;
  background: none;
  border: 0;
}

.collectionTile.carousel .slick-arrow::before {
  content: ' ';
  display: inline-block;
  position: absolute;
  top: 7px;
  font-size: 1.2em;
  font-weight: 100;
  width: 15px;
  height: 15px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  padding: 3px;
}

.collectionTile.carousel .slick-arrow.slick-prev {
  left: 0;
  right: auto;
}

.collectionTile.carousel .slick-arrow.slick-prev::before {
  right: 0;
  transform: rotate(135deg);
}

.collectionTile.carousel .slick-arrow.slick-next {
  right: 0;
  left: auto;
}

.collectionTile.carousel .slick-arrow.slick-next::before {
  left: 0;
  transform: rotate(-45deg);
}

.collectionTile.carousel .slick-arrow:hover {
  opacity: 0.7;
  box-shadow: none;
}

.collectionTile.carousel.sfondo-scuro ul.slick-dots button {
  border-color: #fff;
}

.collectionTile.carousel.sfondo-scuro ul.slick-dots .slick-active button {
  background-color: #fff;
}

.collectionTile.carousel.sfondo-scuro .slick-arrow:before {
  color: #fff;
}

.news-big-photo {
  min-height: 28vw;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.news-big-photo .tileBody {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.news-big-photo .tile-content {
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 1rem 1.5rem;
  color: #212529;
  position: relative;
  border-left: 5px solid #dc2283;
}

.news-big-photo .tile-content h2 a {
  color: #212529;
}

.news-big-photo .tile-content h2 a:focus, .news-big-photo .tile-content h2 a:hover {
  color: #dc2283;
}

.news-big-photo .news-date-more {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.news-big-photo .show-more a {
  font-weight: 700;
  border-bottom: 1px solid #dc2283;
}

.news-big-photo .show-more a:focus, .news-big-photo .show-more a:hover {
  border-color: #9a185c;
}

.news-big-photo .news-date {
  font-size: 0.9em;
  color: #495057;
}

@media (max-width: 767.98px) {
  .news-big-photo .news-date-more {
    flex-flow: row wrap;
  }
  .news-big-photo .tile-content h2 {
    font-size: 1.2em;
  }
}

@media (min-width: 768px) {
  .news-big-photo .tile-content {
    border-left-width: 30px;
    padding: 2rem 3rem;
    font-size: 1.2em;
  }
}

.timeline {
  background-color: #dc2283;
  color: white;
  padding-top: 2.5rem;
}

.timeline .tile-title {
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.timeline .tile-body {
  padding-bottom: 4rem;
}

.timeline .top-icon {
  text-align: center;
  font-size: 3.5rem;
  padding-bottom: 0.5rem;
  line-height: 1;
  display: none;
}

.timeline .month {
  padding-top: 1rem;
  padding-bottom: 0.1rem;
  text-align: center;
}

.timeline .month span {
  display: inline-block;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background-color: white;
  color: #212529;
  position: relative;
}

.timeline .item {
  width: 100%;
  max-width: 402px;
  margin-bottom: 2rem;
  border: 1px solid white;
  background-color: white;
}

.timeline .image {
  line-height: 1;
}

.timeline .item-title {
  color: #dc2283;
  text-decoration: underline;
  font-size: 1.35em;
  line-height: 1.3;
}

.timeline .item-link {
  display: block;
  color: #212529;
  text-decoration: none;
}

.timeline .item-link:hover, .timeline .item-link:focus {
  background-color: #f8f8f8;
}

.timeline .item-link:hover .image, .timeline .item-link:focus .image {
  position: relative;
}

.timeline .item-link:hover .image:before, .timeline .item-link:focus .image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.timeline .item-link:hover .item-title, .timeline .item-link:focus .item-title {
  color: #cf207b;
}

.timeline .item-link:hover .item-title:hover, .timeline .item-link:focus .item-title:hover {
  color: #b01b69;
}

.timeline img,
.timeline iframe {
  max-width: 100%;
}

.timeline img {
  height: auto;
}

.timeline .item-date {
  font-size: 1rem;
}

.timeline .caption {
  padding: 1.875rem 1.5rem;
}

.timeline .item-desc {
  margin: 0;
}

.timeline .show-more {
  text-align: center;
}

.timeline .show-more a {
  display: inline-block;
  background-color: white;
  color: #212529;
  text-transform: uppercase;
  font-size: 0.9rem;
  transition: 0.2s all ease;
  position: relative;
  text-decoration: none;
  border-radius: 25px;
  padding: 0.8rem 1.8rem;
}

.timeline .show-more a:focus, .timeline .show-more a:hover {
  background-color: black;
  color: white;
}

@media (min-width: 768px) {
  .timeline .tile-body {
    position: relative;
  }
  .timeline .tile-body:before {
    content: '';
    position: absolute;
    top: 3.8rem;
    bottom: 4rem;
    left: calc(50% - 2px);
    width: 2px;
    background-color: white;
  }
  .timeline .top-icon {
    display: block;
  }
  .timeline .items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .timeline .month {
    width: 100%;
  }
  .timeline .caption .item-arrow {
    position: relative;
  }
  .timeline .caption .item-arrow:before {
    content: '';
    position: absolute;
    top: 0.6em;
    width: 0;
    height: 0;
    border-style: solid;
  }
  .timeline .caption:before {
    content: '';
    position: absolute;
    left: calc(50% - 6px);
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin-top: calc(0.6em + 3px);
  }
  .timeline .caption.odd .item-arrow:before {
    left: calc(100% + 1.5rem);
    border-width: 7.5px 0 7.5px 10px;
    border-color: transparent transparent transparent white;
  }
  .timeline .caption.even .item-arrow:before {
    right: calc(100% + 1.5rem);
    border-width: 7.5px 10px 7.5px 0;
    border-color: transparent white transparent transparent;
  }
}

.video-gallery .collectionItemImage {
  position: relative;
}

.video-gallery .collectionItemImage .video-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.video-gallery .collectionItemImage .video-icon:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzE0LjA2OCAzMTQuMDY4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMTQuMDY4IDMxNC4wNjg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iX3gzM181Ni5fUGxheSI+CgkJPGc+CgkJCTxwYXRoIGQ9Ik0yOTMuMDAyLDc4LjUzQzI0OS42NDYsMy40MzUsMTUzLjYxOC0yMi4yOTYsNzguNTI5LDIxLjA2OEMzLjQzNCw2NC40MTgtMjIuMjk4LDE2MC40NDIsMjEuMDY2LDIzNS41MzQgICAgIGM0My4zNSw3NS4wOTUsMTM5LjM3NSwxMDAuODMsMjE0LjQ2NSw1Ny40N0MzMTAuNjI3LDI0OS42MzksMzM2LjM3MSwxNTMuNjIsMjkzLjAwMiw3OC41M3ogTTIxOS44MzQsMjY1LjgwMSAgICAgYy02MC4wNjcsMzQuNjkyLTEzNi44OTQsMTQuMTA2LTE3MS41NzYtNDUuOTczQzEzLjU2OCwxNTkuNzYxLDM0LjE2MSw4Mi45MzUsOTQuMjMsNDguMjYgICAgIGM2MC4wNzEtMzQuNjksMTM2Ljg5NC0xNC4xMDYsMTcxLjU3OCw0NS45NzFDMzAwLjQ5MywxNTQuMzA3LDI3OS45MDYsMjMxLjExNywyMTkuODM0LDI2NS44MDF6IE0yMTMuNTU1LDE1MC42NTJsLTgyLjIxNC00Ny45NDkgICAgIGMtNy40OTItNC4zNzQtMTMuNTM1LTAuODc3LTEzLjQ5Myw3Ljc4OWwwLjQyMSw5NS4xNzRjMC4wMzgsOC42NjQsNi4xNTUsMTIuMTkxLDEzLjY2OSw3Ljg1MWw4MS41ODUtNDcuMTAzICAgICBDMjIxLjAyOSwxNjIuMDgyLDIyMS4wNDUsMTU1LjAyNiwyMTMuNTU1LDE1MC42NTJ6IiBmaWxsPSIjRkZGRkZGIi8+CgkJPC9nPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

.photo-gallery .collectionItemTitle {
  display: flex;
}

.photo-gallery .collectionItemTitle svg {
  margin-right: 0.6rem;
  font-size: 1.33em;
  position: relative;
  top: -3px;
}

.organizers {
  padding-top: 1.5rem;
}

.organizers .tileTitle {
  margin-bottom: 0;
  font-weight: 400;
}

.tile-advanced-static .tileTitle {
  font-size: 1.6em;
  font-weight: 400;
  padding-top: 1rem;
}

.banner-solo-foto .tile-container {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}

.banner-solo-foto p {
  margin: 0;
}

.banner-solo-foto img {
  max-width: 100%;
  height: auto;
  float: none;
}

.tileWrapper .existing-content-tile.banner-tile {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tileWrapper .existing-content-tile.banner-tile.with-margins {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .tileWrapper .existing-content-tile.banner-tile.with-margins {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .tileWrapper .existing-content-tile.banner-tile.with-margins {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .tileWrapper .existing-content-tile.banner-tile.with-margins {
    max-width: 960px;
  }
}

@media (min-width: 1100px) {
  .tileWrapper .existing-content-tile.banner-tile.with-margins {
    max-width: 1060px;
  }
}

.tileWrapper .existing-content-tile.banner-tile:not(.with-margins) {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
}

.tileWrapper .existing-content-tile.banner-tile .content-image figure img {
  width: 100%;
  height: auto;
}

.template-search #searchform {
  padding-top: 1rem;
}

.template-search #searchform .input-group {
  display: flex;
}

.template-search #searchform .input-group input.searchPage {
  width: calc(100% - 55px);
}

.template-search #searchform .input-group .input-group-btn {
  width: 55px;
  height: 2.25rem;
}

.template-search #searchform .input-group .input-group-btn input.btn {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iI0ZGRkZGRiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.5rem;
  background-color: #adb5bd;
  width: 0;
  height: 100%;
  padding-left: 2.5rem;
  overflow: hidden;
  text-indent: -999em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
}

.template-search #searchform .input-group .input-group-btn input.btn:hover, .template-search #searchform .input-group .input-group-btn input.btn:focus {
  background-color: #495057;
}

.template-search #searchform .actionMenu {
  position: relative;
  padding: 1.5rem 0 1rem;
}

.template-search #searchform .actionMenu .actionMenuContent {
  display: none;
  position: absolute;
  top: calc(100% - 1rem);
  left: 0;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.625rem 1rem;
  z-index: 10;
}

.template-search #searchform .actionMenu .actionMenuContent #search-filter {
  width: 17em;
}

.template-search #searchform .actionMenu .actionMenuContent #search-filter fieldset {
  margin-top: 0.7em;
  border: 0;
}

.template-search #searchform .actionMenu .actionMenuContent #search-filter fieldset:first-child {
  margin-top: 0;
}

.template-search #searchform .actionMenu .actionMenuContent #search-filter legend {
  margin: 0;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  border: 0;
}

.template-search #searchform .actionMenu .actionMenuContent #search-filter label {
  font-weight: 400;
  font-size: 0.9em;
}

.template-search #searchform .actionMenu.activated .actionMenuContent {
  display: block;
}

.template-search #searchform .actionMenu #search-filter-toggle {
  display: inline-block;
  color: #dc2283;
  padding: 3px 0.5em;
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.template-search #searchform .actionMenu #search-filter-toggle:hover {
  color: #212529;
  background-color: #ccc;
}

.template-search #searchform h1.documentFirstHeading {
  font-size: 2em;
  padding: 0.5rem;
}

.template-search #searchform #search-results-bar {
  display: flex;
  margin-bottom: 1rem;
}

@media (max-width: 991.98px) {
  .template-search #searchform #search-results-bar {
    flex-direction: column;
  }
}

.template-search #searchform #search-results-bar #results-count {
  flex-grow: 2;
  margin: 0;
}

.template-search #searchform #searchResultsSort {
  padding: 0 1.25rem 0 1.875rem;
  padding-left: 0;
  color: #495057;
  font-size: 0.9em;
}

@media (max-width: 991.98px) {
  .template-search #searchform #searchResultsSort {
    padding: 0.625rem 0;
  }
}

.template-search #searchform #searchResultsSort span.autotab-heading {
  margin-right: 1em;
  vertical-align: middle;
}

.template-search #searchform #searchResultsSort #sorting-options a {
  display: inline-block;
  vertical-align: middle;
  padding: 0.3125rem;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 1rem;
  background-color: #f5f5f5;
  line-height: 1;
  margin: 0 4px;
}

.template-search #searchform #searchResultsSort #sorting-options a:focus, .template-search #searchform #searchResultsSort #sorting-options a:hover {
  background-color: #ccc;
}

.template-search #searchform ol.searchResults {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.template-search #searchform ol.searchResults li {
  padding: 0.8em 0;
  border-top: 1px solid #ddd;
}

.template-search #searchform ol.searchResults li:first-child {
  border: 0;
}

.template-search #searchform ol.searchResults li span.result-title {
  display: block;
  padding: 0.2em 0;
}

.template-search #searchform ol.searchResults li span.result-title a {
  color: #212529;
  font-size: 1.1em;
}

.template-search #searchform ol.searchResults li span.result-title a:focus, .template-search #searchform ol.searchResults li span.result-title a:hover {
  color: #dc2283;
}

.template-search #searchform ol.searchResults li > span.discreet {
  display: block;
}

.template-search #searchform ol.searchResults li cite {
  display: block;
}

.template-search #searchform #rss-subscription > a {
  padding-left: 0.625rem;
}

.event-layout header {
  background: #dc2283;
  color: #fff;
  text-align: center;
  padding: 2.5rem;
}

.event-layout header h1.documentFirstHeading {
  padding: 0;
}

.event-layout header .event-object-type {
  font-size: 1.5rem;
}

.event-layout .description {
  font-weight: 600;
  padding: 0;
}

.event-layout #content-core {
  padding: 2rem 0 4rem;
}

@media (max-width: 767.98px) {
  .event-layout #content-core {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.event-layout #parent-fieldname-text {
  padding: 1rem 0;
}

.event-speaker .link-speaker-events a {
  text-decoration: none;
}

.event-speaker .link-speaker-events span {
  padding-right: 0.5rem;
  text-decoration: underline;
}

.event-speaker .speaker-image {
  float: right;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.event-speaker .speaker-bio {
  padding-top: 1rem;
}

.event-speaker .speaker-bio h2 {
  font-size: 1.5rem;
}

.template-listing_speakers .entries {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.template-listing_speakers .entry {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.template-listing_speakers .entry .caption {
  background-color: #eee;
  padding: 0.75rem 1rem;
}

.template-listing_speakers .entry a {
  text-decoration: none;
  color: #212529;
}

.template-listing_speakers .entry a:hover, .template-listing_speakers .entry a:focus {
  color: #dc2283;
}

.template-listing_speakers .entry a:hover .image, .template-listing_speakers .entry a:focus .image {
  position: relative;
}

.template-listing_speakers .entry a:hover .image:after, .template-listing_speakers .entry a:focus .image:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
}

.template-listing_speakers img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .template-listing_speakers .entry {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 992px) {
  .template-listing_speakers .entry {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.event-layout.event h1.documentFirstHeading {
  text-transform: none;
}

.event-layout.event .event-tags {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  margin: 2rem 0;
  border-top: 2px solid;
  border-bottom: 2px solid;
}

.event-layout.event .event-tags .event-tags-list {
  padding-left: 1rem;
  list-style: none;
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
}

.event-layout.event .event-tags .event-tags-list .tag {
  font-size: 1.1rem;
}

.event-layout.event .event-tags .event-tags-list li:not(:last-of-type) {
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
}

.event-layout.event .event-tags .event-tags-list li:not(:last-of-type)::after {
  content: ', ';
  position: absolute;
  right: 0.15rem;
  bottom: 0;
}

.event-layout.event .event-tags .event-tags-list li a {
  text-decoration: none;
}

.event-layout.event .event-tags .event-tags-list li a:hover, .event-layout.event .event-tags .event-tags-list li a:focus {
  text-decoration: underline;
}

.event-layout.event .event-details {
  display: flex;
}

.event-layout.event .event-details svg {
  font-size: 2.5rem;
  margin: 0.5rem 1rem 0.5rem 0;
}

.event-layout.event .event-details > div {
  display: inline-flex;
  width: 50%;
}

.event-layout.event .event-details .event-details-content p {
  margin: 0;
}

.event-layout.event .event-details .event-details-content a {
  padding: 0.5rem 0;
  display: inline-block;
  font-size: 1rem;
}

.event-layout.event .event-details .event-detail-label {
  font-size: 1rem;
  font-weight: 600;
  color: #666;
}

.event-layout.event .event-details .event-detail-title {
  font-size: 2rem;
}

.event-layout.event .event-details .event-detail-title .weekday {
  text-transform: capitalize;
}

@media (max-width: 767.98px) {
  .event-layout.event .event-details {
    flex-wrap: wrap;
  }
  .event-layout.event .event-details > div {
    width: 100%;
    padding: 0.5rem 0;
  }
  .event-layout.event .event-details > div + div {
    padding-top: 1.5rem;
  }
  .event-layout.event .event-details svg {
    margin: 0.5rem 1rem;
    font-size: 2rem;
  }
  .event-layout.event .event-details .event-detail-title {
    font-size: 1.5rem;
  }
}

@media (min-width: 576px) {
  .event-layout.event .event-details .event-when {
    padding-right: 0.5rem;
  }
  .event-layout.event .event-details .event-where {
    padding-left: 0.5rem;
  }
}

.event-layout.event .event-speakers {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .event-layout.event .event-speakers {
    display: flex;
  }
}

.event-layout.event .event-speakers .event-speakers-title {
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
}

.event-layout.event .event-speakers .event-speakers-list ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

@media (min-width: 576px) {
  .event-layout.event .event-speakers .event-speakers-list ul {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .event-layout.event .event-speakers .event-speakers-list ul li {
    padding-left: 0.5rem;
  }
  .event-layout.event .event-speakers .event-speakers-list ul li:not(:last-of-type) {
    padding-right: 0.5rem;
    position: relative;
  }
  .event-layout.event .event-speakers .event-speakers-list ul li:not(:last-of-type)::after {
    content: ', ';
    position: absolute;
    right: 0.1rem;
    bottom: 0;
  }
}

.event-layout.event .event-calendar strong {
  color: #666;
}

.template-event_schedule #content-core {
  max-width: none;
  padding: 0;
}

.template-event_schedule .filters {
  background-color: #eee;
}

.template-event_schedule .reset-filters,
.template-event_schedule .toggle-filters {
  border: 0;
  padding: 0.8rem 0;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
  color: #4a4a4a;
}

.template-event_schedule .reset-filters .svg-inline--fa,
.template-event_schedule .toggle-filters .svg-inline--fa {
  position: relative;
  top: 3px;
  margin-right: 0.7rem;
}

.template-event_schedule .toggle-filters {
  font-size: 1.3125rem;
}

.template-event_schedule .toggle-filters .svg-inline--fa {
  font-size: 1.5em;
}

.template-event_schedule .toggle-filters .fa-angle-up {
  display: none;
}

.template-event_schedule .toggle-filters.open .fa-angle-up {
  display: inline-block;
}

.template-event_schedule .toggle-filters.open .fa-angle-down {
  display: none;
}

.template-event_schedule .toggle-filters:hover, .template-event_schedule .toggle-filters:focus {
  color: #dc2283;
}

.template-event_schedule .reset-wrapper {
  text-align: right;
}

.template-event_schedule #filters-form {
  font-size: 1.125rem;
  font-weight: 700;
}

.template-event_schedule #filters-form > [class^='fields-'] {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 1rem;
}

.template-event_schedule #filters-form .select2-container {
  width: 100%;
  margin: 0;
}

.template-event_schedule #filters-form .select2-container .select2-choice {
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  background-image: none;
  padding-left: 2.5rem;
}

.template-event_schedule #filters-form .select2-container .select2-choice .select2-arrow {
  display: none;
}

.template-event_schedule #filters-form .select2-container ~ .fa-angle-up {
  display: none;
}

.template-event_schedule #filters-form .select2-container.select2-dropdown-open ~ .fa-angle-down {
  display: none;
}

.template-event_schedule #filters-form .select2-container.select2-dropdown-open ~ .fa-angle-up {
  display: inline-block;
}

.template-event_schedule #filters-form .select2-chosen {
  font-weight: 400;
}

.template-event_schedule #filters-form label {
  position: relative;
  margin: 0;
}

.template-event_schedule #filters-form .svg-inline--fa {
  position: absolute;
  bottom: 8px;
  left: 0.6rem;
  font-size: 20px;
  pointer-events: none;
}

.template-event_schedule #filters-form .svg-inline--fa.fa-angle-up, .template-event_schedule #filters-form .svg-inline--fa.fa-angle-down {
  left: auto;
  right: 0.6rem;
}

.template-event_schedule .select2-container-active .select2-choice,
.template-event_schedule .select2-container-active .select2-choices {
  border-color: #dc2283;
}

.template-event_schedule .select2-drop-active {
  border-color: #dc2283;
}

.template-event_schedule .select2-results .select2-highlighted {
  background-color: #eee;
  color: inherit;
}

.template-event_schedule #results-items {
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.template-event_schedule #results-items .event {
  border-bottom: 1px solid #979797;
}

.template-event_schedule .events-group-title {
  font-size: 2.375rem;
  font-weight: 600;
  color: #4a4a4a;
}

.template-event_schedule .events-group {
  padding: 0 0 1.5rem;
}

.template-event_schedule .event-title {
  padding: 1rem 2rem 1rem 0;
  font-size: 1.25em;
  font-weight: 700;
}

.template-event_schedule .event-date,
.template-event_schedule .event-location,
.template-event_schedule .event-type {
  font-size: 1.125rem;
  line-height: 1.875;
}

.template-event_schedule .event-date .svg-inline--fa,
.template-event_schedule .event-location .svg-inline--fa,
.template-event_schedule .event-type .svg-inline--fa {
  margin-right: 0.25rem;
}

.template-event_schedule .event-date {
  position: relative;
  padding-left: 1.5rem;
}

.template-event_schedule .event-date .svg-inline--fa {
  margin: 0;
  position: absolute;
  top: 0.4em;
  left: 0;
}

.template-event_schedule .plone-modal .plone-modal-header {
  background-color: #dc2283;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.template-event_schedule .plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close {
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  opacity: 1;
  width: 1em;
  height: 1em;
  line-height: 0.62em;
  text-align: center;
  margin-top: 0;
  font-size: 1.5em;
}

.template-event_schedule .plone-modal .plone-modal-content {
  border-radius: 5px;
  background-color: white;
}

.template-event_schedule .plone-modal .plone-modal-title {
  font-weight: 300;
}

.template-event_schedule .plone-modal .plone-modal-footer {
  display: none;
}

.template-event_schedule .plone-modal .event-layout > header {
  display: none;
}

@media (min-width: 768px) {
  .template-event_schedule #filters-form > [class^='fields-'] {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .template-event_schedule .events-group-title-wrapper {
    position: relative;
  }
  .template-event_schedule .events-group-title-wrapper:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.8em;
    height: 9px;
    background-color: #4a4a4a;
  }
  .template-event_schedule .events-group-title {
    position: relative;
    background-color: white;
    display: inline-block;
    margin: 0;
    padding-right: 2rem;
  }
  .template-event_schedule #results-items .event {
    display: flex;
  }
  .template-event_schedule .event-title {
    flex: 1 1 33%;
  }
  .template-event_schedule .event-date,
  .template-event_schedule .event-location,
  .template-event_schedule .event-type {
    padding: 1rem;
    flex: 0 1 25%;
  }
  .template-event_schedule .event-date {
    padding-left: 2.5rem;
  }
  .template-event_schedule .event-date .svg-inline--fa {
    top: 1.25em;
    left: 1rem;
  }
  .template-event_schedule .event-type {
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .template-event_schedule #filters-form > [class^='fields-'] {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .template-event_schedule .events-group {
    padding: 3rem 0;
  }
}

.template-venue_view .venueview section.address {
  float: none;
}

@media (min-width: 768px) {
  .template-venue_view #portal-top {
    z-index: 1010;
  }
}

img.mime-icon,
.named-image-widget img,
.named-file-widget img,
img.attachmentLinkIcon {
  height: 1.5rem;
  margin: 0 0.25rem;
}

body.template-folder_contents img.mime-icon {
  margin: 0 0.75rem 0 0.5rem;
}

body.template-file_view #content-core img {
  width: 1.25rem;
  height: auto;
}

.leaflet-wrapper {
  margin: 2rem 0;
}

.collection-leaflet-map {
  margin: 1rem 0;
}

.pat-leaflet .awesome-marker,
.collection-leaflet-map .awesome-marker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pat-leaflet .awesome-marker svg,
.collection-leaflet-map .awesome-marker svg {
  margin-bottom: 1em;
}

.pat-leaflet .leaflet-popup-content a,
.collection-leaflet-map .leaflet-popup-content a {
  color: #dc2283;
  text-decoration: underline;
}

.pat-leaflet .leaflet-popup-content a:hover, .pat-leaflet .leaflet-popup-content a:focus,
.collection-leaflet-map .leaflet-popup-content a:hover,
.collection-leaflet-map .leaflet-popup-content a:focus {
  color: #9a185c;
}

#customer-satisfaction {
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  font-size: 1.2rem;
}

#customer-satisfaction legend {
  font-size: 1.2rem;
}

#customer-satisfaction textarea#rer-customersatisfaction-comment {
  display: block;
  width: 100%;
  height: 34px;
  min-height: 9em;
}

#customer-satisfaction #cs-collapsible-form-area button[type='submit'].plone-btn-primary {
  display: inline-block;
  background-color: #dc2283;
  border-color: #dc2283;
  color: #fff;
  text-transform: uppercase;
  font-size: .9rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  padding: 1em 2.1em;
  text-decoration: none;
  border-radius: 0;
}

#customer-satisfaction #cs-collapsible-form-area button[type='submit'].plone-btn-primary:hover, #customer-satisfaction #cs-collapsible-form-area button[type='submit'].plone-btn-primary:focus {
  background-color: #212529;
  border-color: #212529;
  color: #dc2283;
}

/*# sourceMappingURL=theme.css.map */